<template>
  <q-dialog persistent v-model="login_dialog">
    <div class="login_form">
      <q-form @submit="login">
        <q-card class="text-center q-pa-md">
          <q-card-section class="row items-center q-pb-none">
            <div class="text-h6">{{$t('login')}}</div>
            <q-space />
            <q-btn icon="close" flat round dense @click="close()" />
          </q-card-section>

          <q-card-section>
            <q-input type="email" :placeholder="$t('your_email')" v-model="email" />
            <q-input
              type="text"
              :placeholder="$t('your_polygon_wallet_address')"
              v-model="wallet"
            />
          </q-card-section>

          <q-btn
            type="submit"
            :value="loading ? 'Loading' : 'Send magic link'"
            :disabled="loading"
          >
            {{ $t("send_magic_link") }}
          </q-btn>
        </q-card>
      </q-form>
    </div>
  </q-dialog>

  <q-dialog v-model="alert_dialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">ezverse.io</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ $t("magic_link_prompt") }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup @click="close()" />
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<style lang="sass" scoped>
.login_form
  min-width: 60%
</style>

<script>
import { ref } from "vue";
import { supabase as s } from "../supabase";
import { useRoute, useRouter } from "vue-router";
import { create_wallet, REDIRECT_URL, WEBROOT } from "../web3";
import Web3 from "web3";

export default {
  setup() {
    const loading = ref(false);
    const email = ref("");
    const alert_dialog = ref("false");
    const invalid_wallet_dialog = ref("true");
    const router = useRouter();
    const wallet = ref("");

    console.log("router.query:", useRoute().query);
    const src = ref(useRoute().query.src);
    const login = async () => {
      if (email.value.split(".").length > 3) {
        return;
      }
      try {
        const address = Web3.utils.toChecksumAddress(wallet.value);
      } catch (e) {
        console.error("Invalid ethereum address", e.message);
        alert("Invalid wallet," + wallet.value);

        return;
      }
      try {
        loading.value = true;
        const rid = localStorage.getItem("referer");
        let redirect_url = REDIRECT_URL;
        if (rid) {
          redirect_url =
            redirect_url +
            "/r/" +
            rid +
            "?src=" +
            encodeURIComponent(src.value) +
            encodeURIComponent("&w=") +
            encodeURIComponent(wallet.value);
        } else {
          redirect_url = redirect_url +"/?src=" + encodeURIComponent(src.value) + "&w=" + encodeURIComponent(wallet.value);
          //redirect_url = redirect_url +encodeURIComponent("/?src=" + src.value + "&w=" + wallet.value);
        }

        console.log("redirect to:", redirect_url);
        // redirect_url = "https://nft.ezverse.io"
        const { user, session, error } = await s.auth.signIn(
          {
            email: email.value,
          },
          {
            redirectTo: redirect_url,
          }
        );
        if (error) throw error;
        // alert(redirect_url);
        alert_dialog.value = true;
      } catch (error) {
        alert(error.error_description || error.message);
      } finally {
        loading.value = false;
      }
    };

    return {
      router,
      alert_dialog,
      loading,
      email,
      wallet,
      login_dialog: ref(true),
      login,
    };
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
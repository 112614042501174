import { render } from "./Login.vue?vue&type=template&id=0aae95be&scoped=true"
import script from "./Login.vue?vue&type=script&lang=js"
export * from "./Login.vue?vue&type=script&lang=js"

import "./Login.vue?vue&type=style&index=0&id=0aae95be&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-0aae95be"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QForm,QCard,QCardSection,QSpace,QBtn,QInput,QCardActions});qInstall(script, 'directives', {ClosePopup});
